var t = 0;
var tail = [];
var a;
var f1;
var f2;
var pGlobal;
var isCreateArt = false;
var loopLength = 0;
var currentLoop=0;

var resolution= {
  height : 800,
  width: 800,
  processWidth: 1200,
  processHeight: 1200
}
if( window.innerHeight < 900 ){
  resolution.height = 600;
  resolution.width = 600;
  resolution.processWidth= 1200;
  resolution.processHeight= 1200;
}

export function drawSketch(data) {
    
    var xArray = [];
    var yArray = [];
    var dtArray = [];
    let s1 = 0.0;
    let s2 = 0.0;
    let x11 = -640.0;
    let x12 = 640.0;
    let x21 = -1060.0;
    let x22 = 1060.0;
    let y11 = -360.0;
    let y12 = 360.0;
    let y21 = -(x22 / x12) * y12;
    let y22 = (x22 / x12) * y12;
    // data.x = data.x.splice(0 ,10);
    // data.y = data.y.splice(0 ,10);
    // data.dt = data.dt.splice(0 ,10);
    
  // data.x = [0.0, 5.0, 0.0, -3.0, -6.0, -19.0, -27.0, -36.0, -44.0, -52.0, -58.0, -63.0, -69.0, -72.0, -72.0, -72.0, -70.0, -67.0, -65.0, -61.0, -54.0, -47.0, -41.0, -33.0, -13.0, -12.0, -11.0, -9.0, -5.0, -2.0, 2.0, 7.0, 14.0, 20.0, 27.0, 34.0, 42.0, 51.0, 45.0, 39.0, 35.0, 27.0, 17.0, 9.0, -1.0, -11.0, 2.0, 13.0, 22.0, 32.0, 39.0, 46.0, 55.0, 62.0, 69.0, 76.0, 82.0, 86.0, 89.0, 92.0, 98.0, 106.0, 115.0, 123.0, 134.0, 139.0, 146.0, 151.0, 156.0, 163.0, 169.0, 175.0, 182.0, 189.0, 196.0, 206.0, 213.0, 216.0, 216.0, 215.0, 213.0, 209.0, 203.0, 199.0, 194.0, 190.0, 179.0, 181.0, 183.0, 184.0, 185.0, 184.0, 182.0, 180.0, 195.0, 201.0, 208.0, 215.0, 221.0, 226.0, 230.0, 231.0, 231.0, 227.0, 225.0, 220.0, 216.0, 210.0, 204.0, 194.0, 184.0, 174.0, 166.0, 154.0, 147.0, 140.0, 134.0, 126.0, 116.0, 105.0, 93.0, 84.0, 72.0, 65.0, 67.0, 66.0, 65.0, 62.0, 58.0, 53.0, 48.0, 43.0, 37.0, 30.0, 22.0, 14.0, 2.0, 10.0, 16.0, 21.0, 23.0, 24.0, 23.0, 22.0, 20.0, 11.0, 4.0];
  // data.y = [0.0, 7.0, 14.0, 20.0, 26.0, 25.0, 23.0, 20.0, 16.0, 10.0, 3.0, -4.0, -13.0, -2.0, 4.0, 14.0, 23.0, 31.0, 39.0, 46.0, 55.0, 61.0, 66.0, 71.0, 75.0, 85.0, 95.0, 105.0, 116.0, 127.0, 136.0, 145.0, 155.0, 165.0, 173.0, 181.0, 190.0, 196.0, 210.0, 223.0, 232.0, 241.0, 251.0, 256.0, 260.0, 265.0, 271.0, 274.0, 275.0, 275.0, 274.0, 273.0, 270.0, 267.0, 262.0, 257.0, 251.0, 245.0, 240.0, 234.0, 219.0, 221.0, 221.0, 220.0, 217.0, 214.0, 225.0, 231.0, 238.0, 245.0, 250.0, 255.0, 258.0, 261.0, 263.0, 265.0, 265.0, 259.0, 250.0, 242.0, 231.0, 219.0, 209.0, 201.0, 195.0, 190.0, 176.0, 169.0, 163.0, 155.0, 147.0, 139.0, 128.0, 120.0, 113.0, 110.0, 104.0, 95.0, 86.0, 73.0, 58.0, 42.0, 26.0, 13.0, -0.0, -12.0, 4.0, 21.0, 33.0, 44.0, 52.0, 58.0, 62.0, 66.0, 58.0, 48.0, 40.0, 33.0, 25.0, 17.0, 13.0, 10.0, 8.0, 8.0, -7.0, -18.0, -28.0, -34.0, -42.0, -49.0, -54.0, -59.0, -64.0, -68.0, -71.0, -72.0, -74.0, -65.0, -56.0, -46.0, -35.0, -26.0, -18.0, -8.0, 6.0, 7.0, 6.0];
  // data.dt = [0.0, 0.68, 1.361, 2.041, 2.721, 3.401, 4.082, 4.762, 5.442, 6.122, 6.803, 7.483, 8.163, 8.844, 9.524, 10.204, 10.884, 11.565, 12.245, 12.925, 13.605, 14.286, 14.966, 15.646, 16.327, 17.007, 17.687, 18.367, 19.048, 19.728, 20.408, 21.088, 21.769, 22.449, 23.129, 23.81, 24.49, 25.17, 25.85, 26.531, 27.211, 27.891, 28.571, 29.252, 29.932, 30.612, 31.293, 31.973, 32.653, 33.333, 34.014, 34.694, 35.374, 36.054, 36.735, 37.415, 38.095, 38.776, 39.456, 40.136, 40.816, 41.497, 42.177, 42.857, 43.537, 44.218, 44.898, 45.578, 46.259, 46.939, 47.619, 48.299, 48.98, 49.66, 50.34, 51.02, 51.701, 52.381, 53.061, 53.741, 54.422, 55.102, 55.782, 56.463, 57.143, 57.823, 58.503, 59.184, 59.864, 60.544, 61.224, 61.905, 62.585, 63.265, 63.946, 64.626, 65.306, 65.986, 66.667, 67.347, 68.027, 68.707, 69.388, 70.068, 70.748, 71.429, 72.109, 72.789, 73.469, 74.15, 74.83, 75.51, 76.19, 76.871, 77.551, 78.231, 78.912, 79.592, 80.272, 80.952, 81.633, 82.313, 82.993, 83.673, 84.354, 85.034, 85.714, 86.395, 87.075, 87.755, 88.435, 89.116, 89.796, 90.476, 91.156, 91.837, 92.517, 93.197, 93.878, 94.558, 95.238, 95.918, 96.599, 97.279, 97.959, 98.639, 99.32];
  xArray.push(data.x);
    yArray.push(data.y);
    dtArray.push(data.dt);

    resetSketch(
                pGlobal,
                xArray,
                yArray,
                dtArray,
                x11,
                x12,
                x21,
                x22,
                y11,
                y12,
                y21,
                y22,
                s1,
                s2
              );
              isCreateArt = true;
}

function axis(p5, x11, x12, x21, x22, y11, y12, y21, y22) {
  this.x11 = x11;
  this.x12 = x12;
  this.x21 = x21;
  this.x22 = x22;

  this.y11 = y11;
  this.y12 = y12;
  this.y21 = y21;
  this.y22 = y22;

  this.show = function () {
    var n = 1;

    var dx = (this.x12 / this.x22 + 30) / n;
    var n1 = p5.floor(this.x12 / dx);

    var dy = (this.y12 / this.y22 + 30) / n;
    var n2 = p5.floor(this.y12 / dy);

    p5.stroke(230, 230, 230, 150);

    p5.push();
    p5.stroke(230, 230, 230, 8);

    for (let i = 0; i < n1; i++) {
      let x = i * dx;
      p5.line(x, this.y11, x, this.y12);
    }

    for (let i = 0; i < n1; i++) {
      let x = i * -dx;
      p5.line(x, this.y11, x, this.y12);
    }

    for (let i = 0; i < n2; i++) {
      let y = i * dy;
      p5.line(this.x11, y, this.x12, y);
    }

    for (var i = 0; i < n2; i++) {
      let y = i * -dy;
      p5.line(this.x11, y, this.x12, y);
    }

    p5.pop();

    p5.push();
    p5.stroke(255);
    p5.strokeWeight(0);
    p5.fill(255);
    p5.scale(1, -1);
    p5.text(this.x22, this.x12, 15);
    p5.text(this.x21, this.x11, 15);
    p5.text(this.y22, 0, -1 * this.y12);
    p5.text(this.y21, 0, -1 * this.y11);
    p5.pop();
  };
}
function integral(p5, a, r, m, b, s, c) {
  let v =
    ((c * m * p5.cos(c * r) - c * p5.cos(a * c) * m) * s +
      m * p5.sin(c * r) +
      (-c * m * r - b * c) * p5.cos(c * r) +
      (a * c * p5.cos(a * c) - p5.sin(a * c)) * m +
      b * c * p5.cos(a * c)) /
    (c * c);
  return v;
}
function center(p5, n, t, x_p, FC) {
  var L = x_p[x_p.length - 1];
  var c = ((n + 1) * p5.PI) / L;
  var r = FC[n];
  var s = 1;

  if (n === 0) {
    let x = r * p5.cos(s * c * t),
      y = r * p5.sin(s * c * t);
    return [x, y, r];
  } else {
    let a1 = center(p5, n - 1, t, x_p, FC);
    let x1 = a1[0],
      y1 = a1[1],
      r1 = a1[2];
    let x2 = r * p5.cos(s * c * t),
      y2 = r * p5.sin(s * c * t);
    let x = x1 + x2,
      y = y1 + y2;
    return [x, y, r];
  }
}
function c(p5, x11, y11, r11, r21) {
  let x12 = p5.map(x11, a.x21, a.x22, a.x11, a.x12);
  let y12 = p5.map(y11, a.y21, a.y22, a.y11, a.y12);
  let r12 = p5.map(r11, 0, a.x22, 0, 2 * a.x12);
  let r22 = p5.map(r21, 0, a.x22, 0, 2 * a.x12);
  p5.ellipse(x12, y12, r12, r22);
}
function l(p5, x11, y11, x21, y21) {
  let x12 = p5.map(x11, a.x21, a.x22, a.x11, a.x12);
  let y12 = p5.map(y11, a.y21, a.y22, a.y11, a.y12);
  let x22 = p5.map(x21, a.x21, a.x22, a.x11, a.x12);
  let y22 = p5.map(y21, a.y21, a.y22, a.y11, a.y12);
  p5.line(x12, y12, x22, y22);
}

function r(p5, x11, y11) {
  let x12 = p5.map(x11, a.x21, a.x22, a.x11, a.x12);
  let y12 = p5.map(y11, a.y21, a.y22, a.y11, a.y12);
  return [x12, y12];
}
function fourier(p5, x_p, y_p) {
  this.x_p = x_p;
  this.y_p = y_p;
  this.FC = [];

  this.coefficient = function () {
    let x_p = this.x_p;
    let y_p = this.y_p;
   
    for (var n = 0; n < 500; n++) {
      var a_n = 0.0;
      for (var i = 0; i < x_p.length - 1; i++) {        
        let x1 = x_p[i],
          x2 = x_p[i + 1];
        let y1 = y_p[i],
          y2 = y_p[i + 1];
        var l = x_p[x_p.length - 1];
        var m = (y2 - y1) / (x2 - x1);
        var s = x1;
        var b = y1;
        var c = ((n + 1) * p5.PI) / l;
        a_n += integral(p5, x1, x2, m, b, s, c);   
      }
      
      let CF = (2 / l) * a_n;
      this.FC.push(CF);

    }
  };

  this.show = function () {
    let x_p = this.x_p;
    let y_p = this.y_p;
    let FC = this.FC;

    // reduce it to increase speed
    for (var n = 0; n < 40; n++) {
      if (n === 0) {
        let a1 = center(p5, n, t, x_p, FC);
        let x1 = a1[0],
          y1 = a1[1],
          r1 = a1[2];
        p5.stroke("#c2c6c7");
        c(p5, 0, 0, r1, r1);
        p5.stroke("#c2c6c7");
        l(p5, 0, 0, x1, y1);
      } else {
        let a1 = center(p5, n - 1, t, x_p, FC);
        let a2 = center(p5, n, t, x_p, FC);
        let x1 = a1[0],
          y1 = a1[1],
          r1 = a1[2];
        let x2 = a2[0],
          y2 = a2[1],
          r2 = a2[2];
        p5.stroke("#c2c6c7");
        c(p5, x1, y1, r2, r2);
        p5.stroke("#c2c6c7");
        l(p5, x1, y1, x2, y2);
      }
    }
    let a3 = center(p5, FC.length - 1, t, x_p, FC);
    let x3 = a3[0],
      y3 = a3[1],
      r3 = a3[2];
    l(p5, x3, y3, 10000.0, y3);
  };
}
function tail_update(p5) {
  //console.log(p5, "p5");
  let a1 = center(p5, f2.FC.length - 1, t, f2.x_p, f2.FC);
  let a2 = center(p5, f1.FC.length - 1, t, f1.x_p, f1.FC);
  let y1 = a1[1];
  let x1 = a2[1];
  let x = r(p5, x1, y1)[0],
    y = r(p5, x1, y1)[1];
  // console.log(x);
  tail.push(new p5.constructor.Vector(x, y));
  if (tail.length > 1) {
    for (var i = 1; i < tail.length - 1; i++) {
      p5.ellipse(tail[i].x, tail[i].y, 2, 2);
      p5.ellipse(tail[tail.length - 1].x, tail[tail.length - 1].y, 6, 6);
    }
  }
  // if (tail.length === f2.FC.length) {
  //   p5.noLoop();
  // }
}

function resetSketch(
  p5,
  xArray,
  yArray,
  dtArray,
  x11_i,
  x12_i,
  x21_i,
  x22_i,
  y11_i,
  y12_i,
  y21_i,
  y22_i,
  s1_i,
  s2_i
) {

  
  var x11 = x11_i;
  var x12 = x12_i;
  var x21 = x21_i;
  var x22 = x22_i;
  var y11 = y11_i;
  var y12 = y12_i;
  var y21 = y21_i;
  var y22 = y22_i;
  var s1 = s1_i;
  var s2 = s2_i;
  pGlobal.redraw();
  pGlobal.loop();
  loopLength = 0;
  currentLoop = 0;
  a = new axis(p5, x11, x12, x21, x22, y11, y12, y21, y22);
  loopLength = xArray[0].length;
  
  f1 = new fourier(p5, dtArray[0], xArray[0]);
  f1.coefficient();
  f2 = new fourier(p5, dtArray[0], yArray[0]);
  //console.log(f2);
  f2.coefficient();

  t = 0.0;
  tail = [];
}

function sketch(p5) {

  p5.setup = () => {
    pGlobal = p5;
    
    p5.createCanvas(resolution.processWidth, resolution.processHeight);   
    p5.rotate(180 * p5.PI / 180);    
    p5.canvas.attributes.style.nodeValue = "width:"+resolution.width+"px; height: "+resolution.height+"px; overflow: scroll; ";
    // p5.canvas.attributes.scroll.nodeValue = "800";
    
  };

  p5.draw = () => {
    if(loopLength > -1 && loopLength < currentLoop){
      p5.noLoop();
      pGlobal.noLoop();
    } else {
      pGlobal.loop();

      if (isCreateArt) {
        currentLoop++;  
        p5.background("#ffffff");
        p5.translate(resolution.processWidth / 2, resolution.processHeight / 2);
        //  p5.rotate(-p5.PI / 1);
        //  p5.scale(1, -1);
        p5.strokeWeight(2);
        p5.noFill();
  
        p5.push();
        p5.translate(-230, 0);
        f2.show();
        p5.pop();
  
        p5.push();
        p5.translate(0, -200);      
         p5.scale(1, -1);
         p5.rotate(-p5.PI / 2);
  
        f1.show();
        p5.pop();
  
        p5.push();
        p5.stroke(0);
        p5.strokeWeight(1);
        a.show();
        p5.pop();
  
        p5.push();
        p5.noStroke();
        p5.fill(0);
        tail_update(p5);
        p5.pop();      
        t += 0.68;
        if(loopLength > 0 && loopLength < currentLoop){
          p5.noLoop();
          pGlobal.noLoop();
        }      
        
      }
    }      
    
  };
}


function handleP5Events(action){  
  if( action === 'stop') {    
    pGlobal.noLoop();
  }
  if( action === 'resume') {    
    pGlobal.loop();
    pGlobal.redraw();         
  }
}

export { sketch, handleP5Events };
